import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import regeneratorRuntime from "/codebuild/output/src2611692786/src/web-cloud-prod-vf-it/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { useQuery, useQueryClient } from "react-query";
import { LoggerInstance, nextClient } from "@vfit/shared/providers";
import { errorMock } from "@vfit/consumer/data-access";
import { API, getFromLocalStorageByKey } from "@vfit/shared/data-access";
import { overrideOperatorsListFixed } from "./portabilityNumber.utils";
/**
 * API service multiPlayOfferingConfiguration to retrieve user consents
 * @param cartItemId
 * @param cartId
 * @param customOptions
 * @returns
 */ export var multiPlayOfferingConfigurationService = function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(cartItemId, cartId, customOptions) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", nextClient.get("".concat(API.MULTI_PLAYING_OFFERING_CONFIGURATION, "/").concat(cartItemId, "?salesChannel=selfService&context=shoppingCart.Id==").concat(cartId, "&configurationType=descriptiveCharacteristics&levelOfData=portIn"), _object_spread({
                        searchParams: undefined
                    }, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.headers) && {
                        headers: customOptions.headers
                    }, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.silentLoginHeaders) && {
                        silentLoginHeaders: customOptions.silentLoginHeaders
                    })));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function multiPlayOfferingConfigurationService(cartItemId, cartId, customOptions) {
        return _ref.apply(this, arguments);
    };
}();
export var useMultiPlayOfferingConfiguration = function(CMSOperatorsList, customOptions) {
    var ref, ref1;
    var queryClient = useQueryClient();
    var shoppingCart = getFromLocalStorageByKey("shoppingCart");
    var associateCustomer = getFromLocalStorageByKey("associateCustomer");
    var cartId = shoppingCart === null || shoppingCart === void 0 ? void 0 : shoppingCart.id;
    var cartItemId = shoppingCart === null || shoppingCart === void 0 ? void 0 : (ref = shoppingCart.cartItem) === null || ref === void 0 ? void 0 : (ref1 = ref[0]) === null || ref1 === void 0 ? void 0 : ref1.id;
    var enabler = Boolean(cartItemId) && Boolean(cartId) && Boolean(associateCustomer);
    return useQuery([
        "multiPlayOfferingConfiguration"
    ], function() {
        return multiPlayOfferingConfigurationService(cartItemId, cartId);
    }, {
        enabled: (customOptions === null || customOptions === void 0 ? void 0 : customOptions.enabled) !== undefined ? customOptions.enabled : enabler,
        onSuccess: function(data) {
            var overridenData = overrideOperatorsListFixed(data, CMSOperatorsList);
            localStorage.setItem("multiPlayOfferingConfiguration", JSON.stringify(overridenData));
            queryClient.setQueryData("multiPlayOfferingConfigurationQuery", overridenData);
        },
        onError: function(error) {
            LoggerInstance.error("ERROR - multiPlayOfferingConfiguration: ", error);
            localStorage.setItem("multiPlayOfferingConfiguration", JSON.stringify(errorMock("multiPlayOfferingConfiguration", error)));
        }
    });
};
